import React from "react"
import Layout from '../components/layout.js'

export default () => {
  return (
    <div id='home'>
      <Layout>
      </Layout>
    </div>
  )
}

